/*
Author: Brad D'Costa
Date: 13/12/17
Copyright © 2017 Ernst & Young. All rights reserved.

Constants for the deployed environment.
*/
import AWS from 'aws-sdk';

export const cognito = {
  REGION: 'ap-southeast-2',
  USER_POOL_ID: 'ap-southeast-2_NQUm2ASJa',
  USER_POOL_WEB_CLIENT_ID: '3l75no8vdlvlslqtc925qrd3i4',
  OAUTH: {
    DOMAIN: '$BR_COGNITO_OAUTH_DOMAIN',
    SCOPE: ['phone', 'email', 'profile', 'openid'],
    REDIRECT_URL: '$BR_COGNITO_REDIRECT_URL',
    RESPONSE_TYPE: 'code',
    OPTIONS: {
      DATA_COLLECTION_FLAG: true,
    },
  },
};
export const IS_STAFF_APP = '$BR_IS_STAFF_APP';
export const AWS_REGION = 'ap-southeast-2';
export const AWS_API_VERSION = '2015-03-31';
export const AWS_CLOUDWATCH_API_VERSION = '2010-08-01';
export const AWS_STUBBED = false;
export const AWS_LAMBDA_PREFIX = 'libe';
export const AWS_LAMBDA_CONFIG_ITEM = 'dev';
export const AWS_LAMBDA_QUALIFIER = '$LATEST';
export const AWS_LAMBDA_SERVICES = {
  ADMIN: 'admin',
  USER: 'user',
  PERSON: 'person',
  MEMBER: 'member',
  CREW: 'crew',
  ABOUTME: 'aboutme',
  PLANDATA: 'plandata',
  REFERENCE: 'reference',
  NOTIFICATION: 'notification',
  PDFGENERATION: 'pdfGeneration',
  PARTNERPROVIDER: 'partnerProvider',
  CUSTOMERFEEDBACK: 'ratings',
  PROVIDER: 'provider',
  DEMO_PROFILE: 'demoProfiles',
  LOYALTY:'loyalty'
};

export const GOOGLE_CAPTCHA_CLIENT_KEY = '6LcX-akUAAAAAD3Z5QlHBYT2bAq0FSI7gpwhcj1V';
export const GA_TRACKING_CODE = 'UA-116820193-2';

AWS.config.region = AWS_REGION;

// The app constant
export const APP_ID = 'Leap in';
export const APP_WEB_CHANNEL = 'web';

// App version info
export const APP_VERSION = '7.4.0';
export const BUILD_VERSION = '5535';

export const COGNITO_IDENTITY_POOL = 'ap-southeast-2:c758a831-e889-4863-ad22-8bb2cce9ba74';
export const COGNITO_USER_POOL_ID = 'ap-southeast-2_NQUm2ASJa';
export const COGNITO_CLIENT_ID = '3l75no8vdlvlslqtc925qrd3i4';
export const COGNITO_EMAIL_SIGNUP_URL = `cognito-idp.${AWS_REGION}.amazonaws.com/${COGNITO_USER_POOL_ID}`;

export const DEMO_COGNITO_USER_POOL_ID = 'ap-southeast-2_AzkE0UVHn';
export const DEMO_COGNITO_CLIENT_ID = '1h82bcml9csakqqrbl383tt98k';
export const DEMO_COGNITO_EMAIL_SIGNUP_URL = `cognito-idp.${AWS_REGION}.amazonaws.com/${DEMO_COGNITO_USER_POOL_ID}`;

export const PM_DEMO_EMAIL = 'eltonrobinson@leapin.com.au';
export const PR_DEMO_EMAIL = 'mollyjones@leapin.com.au';
export const DEMO_PASSWORD = 'leapinDemo';
export const API_URL = 'https://api-dev.leapin.com.au';
export const DEMO_CREATION_URL = 'https://api-dev.leapin.com.au/create-demo/createdemoprofile';
export const DEMO_CREATION_API_KEY = 'JtkckO2DQIeRHEQDxBDs4DkIqnU7uQe2DZgHgYCd';
export const PM_SIGN_UP_URL = 'https://web3-dev1.leapin.com.au/for-members/ndis-plan-management/register/';

export const GOOGLE_WEB_CLIENT_ID = '1084494254514-4njlcl761p8gamv0h0s2jjr1g4ts6nca.apps.googleusercontent.com';

export const MDS_CLIENT_SERVICE = {
  BASE_URL: 'https://api.mapds.com.au',
  SUGGEST_PATH: '/place/suggest',
  API_KEY: 'TGVhcEluX1dTQVBJOn5GMkFTSnA0',
};

export const DATA_DOG = {
  ENABLED: true,
  APPLICATION_ID: '512cb2ea-cadf-4671-97c1-6b23c7417ee7',
  CLIENT_TOKEN: 'pub05956baa6744e74e5373dc546493ca3a',
  DD_SERVICE: 'planready-web-dev',
  allowedTracingUrls: [
    (url) => url.startsWith('$API_URL')
  ]
};
