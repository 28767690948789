import React from 'react';
import {Platform} from 'react-native';
import WebHtmlContentRenderer from './WebHtmlContentRenderer';
import ReactNativeRenderHtml, {
  DynamicHtmlContentMobile,
} from './ReactNativeRenderHtml';
import ErrorBoundary from './ErrorBoundary';
import StandardText from '../Atoms/StandardText';

// Main dynamic HTML content type

export type DynamicHtmlContent = {
  web: {
    html: string;
  };
  mobile: DynamicHtmlContentMobile;
};

export default function RenderDynamicHTML(content: DynamicHtmlContent) {
  return (
    <ErrorBoundary
      fallback={<StandardText>{'Failed to render HTML content'}</StandardText>}>
      {Platform.OS === 'web' ? (
        <WebHtmlContentRenderer html={content.web.html} />
      ) : (
        <ReactNativeRenderHtml {...content.mobile} />
      )}
    </ErrorBoundary>
  );
}
