import React from 'react';

type WebHtmlContentRendererProps = {
  html: string;
};

export default function WebHtmlContentRenderer({
  html,
}: WebHtmlContentRendererProps) {
  return (
    <div
      dangerouslySetInnerHTML={{__html: html}}
      style={{width: '100%', display: 'flex', fontFamily: 'arial'}}
    />
  );
}
