import React, {useMemo} from 'react';
import HTML, {
  HTMLContentModel,
  HTMLElementModel,
  MixedStyleDeclaration,
  MixedStyleRecord,
} from 'react-native-render-html';
import {Platform, useWindowDimensions} from 'react-native';
import logger from 'helpers/Logger';

// Define reusable style condition
type ConditionalStyle = {
  maxWidth?: number;
  minWidth?: number;
  platform?: 'ios' | 'android' | 'web';
  styles: Record<string, any>;
};

// Style definition per element id
export type HTMLRendererProps = {
  tagsStyles?: Readonly<Record<string, MixedStyleDeclaration>>;
  baseStyle?: MixedStyleDeclaration;
  classesStyles?: MixedStyleRecord;
};

export type DynamicHtmlContentMobile = {
  html: string;
  idsStyles?: {
    [elementId: string]: {
      default: Record<string, any>;
      conditions: ConditionalStyle[];
    };
  };
  rendererProps: HTMLRendererProps;
  svg?: {
    [elementId: string]: {
      html: string;
      styles: Record<string, any>;
    };
  };
};

const systemFonts = [
  'FoundersGrotesk-Bold',
  'FoundersGrotesk-Regular',
  'Arial',
  'ArialMT',
  'Arial-BoldMT',
];

export default function ReactNativeRenderHtml(
  content: DynamicHtmlContentMobile,
) {
  const {width} = useWindowDimensions();

  // Process conditional styles into final idsStyles object
  const computeIdsStyles = () => {
    if (!content.idsStyles) return {};

    const result: Record<string, any> = {};

    // Process each element's styles
    Object.entries(content.idsStyles).forEach(([elementId, styleConfig]) => {
      // Start with default styles
      const finalStyles = {...styleConfig.default};

      // Apply matching conditional styles
      styleConfig.conditions?.forEach(condition => {
        const platformMatches =
          !condition.platform || condition.platform === Platform.OS;
        const maxWidthMatches =
          !condition.maxWidth || width <= condition.maxWidth;
        const minWidthMatches =
          !condition.minWidth || width >= condition.minWidth;

        // If all conditions match, apply these styles
        if (platformMatches && maxWidthMatches && minWidthMatches) {
          Object.assign(finalStyles, condition.styles);
        }
      });

      result[elementId] = finalStyles;
    });

    return result;
  };

  const SvgRenderer = ({...props}) => {
    try {
      const svgId = props?.tnode?.id;
      const svgConfig = content.svg?.[svgId];

      if (!svgConfig?.html) {
        return null;
      }

      const SvgXml = require('react-native-svg').SvgXml;
      return <SvgXml xml={svgConfig.html} {...(svgConfig.styles || {})} />;
    } catch (error) {
      logger.error('Error rendering SVG:', error);
      return null;
    }
  };

  // Memoize static objects to prevent unnecessary rerenders
  const customHTMLElementModels = useMemo(
    () => ({
      svg: HTMLElementModel.fromCustomModel({
        tagName: 'svg',
        contentModel: HTMLContentModel.block,
        isOpaque: true,
      }),
    }),
    [],
  );

  const renderers = {
    svg: SvgRenderer,
  };

  // Set default font family
  const rendererProps = useMemo(() => {
    const baseStyle = {
      fontFamily: 'Arial',
      ...content?.rendererProps?.baseStyle,
    };

    return {
      ...content?.rendererProps,
      baseStyle,
    };
  }, [content?.rendererProps]);

  return (
    <HTML
      source={{html: content.html}}
      contentWidth={width}
      renderers={renderers}
      customHTMLElementModels={customHTMLElementModels}
      systemFonts={systemFonts}
      idsStyles={computeIdsStyles()}
      {...rendererProps}
    />
  );
}
