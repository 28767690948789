/*
 * Author: Andrew Seeley
 * Date: 19/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Budgets and Payments Screen when the user has access.
 */

import React, {Component} from 'react';
import {View, StyleSheet, Image} from 'react-native';

import {StandardText} from '../Components/Atoms';
import {
  SpendingPieChart,
  SpendingSupportItem,
  SummaryTile,
  BrandActivityIndicator,
  GridView,
  CurrencyText,
  RenderDynamicHTML,
} from '../Components/Molecules';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  BUDGETS_AND_PAYMENTS_SCREEN as messages,
  BUDGET_SPEND_GRAPHS,
} from '../Constants/Messages';

import {GREEN_BUDGET, BLACK_BUDGET_TITLE} from '../Styles/SpendingBarColors';

import {GreyClockIcon3x} from '../assets/images';
// import CustomIcon from '../assets/images/CustomIcon';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandColors} from '../Styles/Colours';

// Extensions
// import Orientation from 'react-native-orientation-enhanced';
// import DeviceInfo from 'react-native-device-info';

import {isTablet} from '../Helpers/PlatformSynchronizer';

import {
  formatDDMMMYYDate,
  formatDate,
  parseFormattedNumber,
} from '../Helpers/Utils';
import {remove, sumBy} from 'lodash';

const PLAN_RESULT_INDEX = {
  TRANSACTIONS: 0,
  BUDGETS: 1,
  BUDGET_CATEGORIES: 2,
};

class BudgetsPaymentsView extends Component {
  state = {
    contentContainerDimensions: undefined,
    orientation: '',
    isOpen: this.props.sideMenu ? this.props.sideMenu.sideMenuIsOpen : false,
  };

  constructor(props: any) {
    super(props);
    this._renderSupportGroups.bind(this);
    this._renderSection.bind(this);
    this._viewSpendingSupportItem.bind(this);
    this._getStyleByDeviceInfo.bind(this);
  }
  // UNSAFE_componentWillMount() {
  //   Orientation.getOrientation((err, orientation) => {
  //     this.setState({ orientation });
  //   });
  // }
  // componentWillUnmount() {
  //   Orientation.removeOrientationListener(this._orientationDidChange);
  // }

  // _orientationDidChange = orientation => {
  //   this.setState({ orientation });
  // };

  UNSAFE_componentWillReceiveProps(props: any) {
    this.setState({
      isOpen: props.sideMenu ? props.sideMenu.sideMenuIsOpen : false,
    });
  }

  // componentDidMount() {
  //   Orientation.addOrientationListener(this._orientationDidChange);
  // }

  find_dimesions(layout: any) {
    const {width} = layout;
    if (!types.isWeb) {
      this.setState({
        viewWidth: width,
      });
    }
  }

  getTrancheUnreleased(tranches: any) {
    if (tranches?.length) {
      return sumBy(tranches, tranche =>
        tranche.released ? 0 : parseFormattedNumber(tranche.amount),
      );
    }
    return 0;
  }

  getUnreleasedAmount() {
    let unreleasedAmount = 0;
    const budget = this.props.budget;
    types2.SUPPORT_GROUPS.map(group => {
      const data = budget[group.apiKey];
      data?.map(item => {
        unreleasedAmount += this.getTrancheUnreleased(item.tranches);
      });
    });
    return unreleasedAmount;
  }

  render() {
    const budget = this.props.budget;
    const isMultiTranche = this.props.isMultiTranche;

    if (Object.getOwnPropertyNames(budget).length === 0 || this.state.loading) {
      return null;
    }
    const spend = budget.budget;
    const planStartDate = budget.startDate;
    const planEndDate = budget.endDate;
    const spendTitle = {
      totalSpend: spend['total'],
      title: BUDGET_SPEND_GRAPHS.TOTAL_FUNDING,
      textColors: BLACK_BUDGET_TITLE,
    };

    const sortOrder = [types2.BUDGET_KEYS.UNSPENT, types2.BUDGET_KEYS.SPENT];
    if (isMultiTranche) {
      spend.unreleased = this.getUnreleasedAmount();
      sortOrder.push(types2.BUDGET_KEYS.UNRELEASED);
    }
    return (
      <View>
        <View
          style={[
            CommonStyles.flexRow,
            CommonStyles.justifyEnd,
            CommonStyles.BudgetsPaymentsViewFlex1,
          ]}>
          {!isMultiTranche && (
            <View
              style={[
                CommonStyles.justifyFlexRowStart,
                CommonStyles.alignItemsCenter,
                CommonStyles.flexWrap,
              ]}>
              <View style={[CommonStyles.marginRight5]}>
                <CurrencyText
                  value={spendTitle.totalSpend}
                  isPositive
                  integerStyle={[
                    CommonStyles.customFont,
                    this._getStyleByDeviceInfo(
                      CommonStyles.font30,
                      CommonStyles.font20,
                    ),
                    {color: spendTitle.textColors.budget},
                  ]}
                  decimalStyle={[
                    CommonStyles.customFont,
                    this._getStyleByDeviceInfo(
                      CommonStyles.font18,
                      CommonStyles.font15,
                    ),
                    {color: spendTitle.textColors.budget},
                  ]}
                  containerStyle={[CommonStyles.flexDirectionRow]}
                />
              </View>
              <StandardText
                style={[
                  CommonStyles.customFont,
                  CommonStyles.textAlignVerticalBottom,
                  this._getStyleByDeviceInfo(
                    CommonStyles.font18,
                    CommonStyles.font15,
                  ),
                  {color: spendTitle.textColors.funding},
                ]}>
                {` ${spendTitle.title}`}
              </StandardText>
            </View>
          )}
          <View
            style={[
              CommonStyles.justifyFlexRowEnd,
              CommonStyles.alignItemsCenter,
              CommonStyles.flexWrap,
              types.isWeb ? CommonStyles.paddingRight25 : null,
            ]}>
            <View
              style={[
                CommonStyles.flexDirectionColumn,
                CommonStyles.justifyEnd,
                CommonStyles.BudgetsPaymentsViewFlex1,
              ]}>
              {this.props.navigationParams &&
              this.props.navigationParams.planStatus ===
                types.PLAN_STATUS.CLAIMABLE &&
              this.props.navigationParams.planClaimUntilDate ? (
                <View
                  style={[
                    CommonStyles.flexRow,
                    CommonStyles.justifyContentFlexEnd,
                    CommonStyles.marginBottom5,
                  ]}>
                  <StandardText
                    style={[
                      CommonStyles.customFontV,
                      CommonStyles.customFontMedium,
                      CommonStyles.font14,
                      CommonStyles.textAlignRight,
                    ]}>
                    {types2.CLAIM_UNTIL}
                  </StandardText>
                  <StandardText
                    style={[
                      CommonStyles.customFontV,
                      CommonStyles.marginLeft5,
                      CommonStyles.customFontMedium,
                      CommonStyles.font14,
                      CommonStyles.textAlignRight,
                    ]}>
                    {`${formatDate(
                      this.props.navigationParams.planClaimUntilDate,
                    )}`}
                  </StandardText>
                </View>
              ) : null}
              {this.props.navigationParams.planStatus &&
              this.props.navigationParams.planStatus !==
                types.PLAN_STATUS.ACTIVE ? (
                <View
                  style={[
                    CommonStyles.flexDirectionRow,
                    CommonStyles.justifyContentFlexEnd,
                  ]}>
                  <View
                    style={[
                      CommonStyles.MultiPlanContainer,
                      types.PLANS_TYPE[this.props.navigationParams.planStatus]
                        .bubbleBgColorStyle,
                    ]}>
                    <StandardText
                      style={[
                        CommonStyles.customFontBold,
                        CommonStyles.font18,
                        types.PLANS_TYPE[this.props.navigationParams.planStatus]
                          .bubbleTextColorStyle,
                      ]}>
                      {
                        types.PLANS_TYPE[this.props.navigationParams.planStatus]
                          .desc
                      }
                    </StandardText>
                  </View>
                </View>
              ) : null}
              <View
                style={[
                  CommonStyles.justifyFlexRowEnd,
                  CommonStyles.alignItemsCenter,
                  CommonStyles.flexWrap,
                ]}>
                <StandardText
                  style={[
                    CommonStyles.customFont,
                    BrandStyles.TextColor10,
                    this._getStyleByDeviceInfo(
                      CommonStyles.font18,
                      CommonStyles.font15,
                    ),
                  ]}>
                  {`${formatDDMMMYYDate(planStartDate)} -`}
                </StandardText>
                <StandardText
                  style={[
                    CommonStyles.customFont,
                    BrandStyles.TextColor10,
                    this._getStyleByDeviceInfo(
                      CommonStyles.font18,
                      CommonStyles.font15,
                    ),
                  ]}>
                  {` ${formatDDMMMYYDate(planEndDate)}`}
                </StandardText>
              </View>
            </View>
          </View>
        </View>

        <View style={CommonStyles.flex1}>
          <SpendingPieChart
            isMobile={this.props.isMobile}
            viewWidth={this.props.viewWidth}
            showSummaryTitle={false}
            orientation={this.state.orientation}
            isOpen={this.state.isOpen}
            isTablet={isTablet}
            budget={spend}
            budgetColors={GREEN_BUDGET}
            titleComponentProps={spendTitle}
            titleComponentShow={false}
            displayText={true}
            sortOrder={sortOrder}
            pieChartSortOrder={[
              types2.BUDGET_KEYS.SPENT,
              types2.BUDGET_KEYS.UNSPENT,
              types2.BUDGET_KEYS.UNRELEASED,
            ]}
            isMultiTranche={isMultiTranche}
          />
        </View>
        {this._renderPromotionalContent()}
        {this._renderSupportGroups()}
      </View>
    );
  }

  _renderPromotionalContent() {
    const promotionalContent = this.props.budget?.promotionalContent;

    return (
      <View style={CommonStyles.flex1}>
        {promotionalContent && (
          <View key={1} style={[CommonStyles.flex1, CommonStyles.margin10, CommonStyles.marginTop20]}>
            <RenderDynamicHTML {...promotionalContent} />
          </View>
        )}
      </View>
    );
  }

  /**
   * Render's all the support group sections, and content inside it
   */
  _renderSupportGroups() {
    const budget = this.props.budget;
    return types2.SUPPORT_GROUPS.map((group, index) => {
      const data = [...budget?.[group.apiKey]];

      // Move core flexible to the top
      if (group.apiKey === 'core') {
        const targetObj = remove(
          data,
          item => item?.name === types.CORE_FLEXIBLE,
        )[0];
        if (targetObj) {
          data.unshift(targetObj);
        }
      }

      if (data != undefined && data.length != 0) {
        return (
          <View
            onLayout={event => {
              this.find_dimesions(event.nativeEvent.layout);
            }}>
            <View style={this._determineCategoryContainerStyle()}>
              {this._renderSection(index, group.displayText, group.icon)}
            </View>
            <GridView
              viewWidth={this.props.viewWidth}
              isMobile={this.props.isMobile}
              maximumColumns={
                this.state.viewWidth > types2.MEDIA_QUERY.WIDE ? 3 : 2
              }
              isStatic={
                this.state.viewWidth > types2.MEDIA_QUERY.LARGE ? true : false
              }
              itemDimension={400}>
              {this.generateBudgetItems(data, group.apiKey)}
            </GridView>
          </View>
        );
      }
    });
  }

  _determineCategoryContainerStyle = () => {
    let styles: any = [];
    if (types.isWeb) {
      styles = [CommonStyles.marginTop20];
    }
    return styles;
  };

  generateBudgetItems(data: any, group: any) {
    const isMultiTranche = this.props.isMultiTranche;
    const clickable =
      this.props.navigationParams.planStatus &&
      this.props.navigationParams.planStatus !== types.PLAN_STATUS.ACTIVE;

    return data.map((item: any, index: any) => {
      const budget = {...item.budget};
      const sortOrder = [types2.BUDGET_KEYS.SPENT, types2.BUDGET_KEYS.UNSPENT];
      if (isMultiTranche) {
        budget.unreleased = this.getTrancheUnreleased(item.tranches);
        sortOrder.push(types2.BUDGET_KEYS.UNRELEASED);
      }
      return (
        <SpendingSupportItem
          clickable={!clickable}
          key={item.name}
          name={item.name}
          color={item.color}
          secondaryColor={item.secondaryColor}
          icon={item.icon}
          budget={budget}
          group={group}
          item={item}
          index={item.categoryId}
          viewCallback={this._viewSpendingSupportItem}
          sortOrder={sortOrder}
          isMultiTranche={isMultiTranche}
        />
      );
    });
  }

  /**
   * Render a section for support budgets
   * @param {String} key Unique key for the section
   * @param {String} headerString The string for the header
   * @param {Any} icon The icon for the header
   */
  _renderSection = (key: any, headerString: any, headerIcon: any) => {
    return (
      <SummaryTile
        key={key}
        headerDisplayString={headerString}
        headerIconImage={headerIcon}
        screenType={types.SCREEN_TYPE_MAIN}
        HeaderTextColor={BrandStyles.TextColor2}
        HeaderBoarderColor={BrandStyles.borderColor2}
        containsData={false} // This will always be charcol with no button
        showButton={false}
        hideYellowStar={true}
      />
    );
  };

  /**
   * Callback from a spendingsupport item tile to open up the next screen for that item
   * @param {Object} item The item to open up the spending support for
   */
  _viewSpendingSupportItem = (group: any, index: any) => {
    this.props.navigation.navigate('SpendingSupportItemDetail', {
      group: group,
      index: index,
    });
  };

  _getStyleByDeviceInfo = (tab: any, normal: any) => (isTablet ? tab : normal);
}

//For the reducer connection
const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  budget: state.BudgetReducer,
  sideMenu: state.SideMenuReducer,
  navParams: state.NavigationParamsReducer,
});

export default connect(mapStateToProps)(BudgetsPaymentsView);
